import IMask from "imask";
import Fetch from "./fetch";
import {closeModal, openBlock, openModal} from "./modal";
import {checkPhoneCountry} from "../utils";
import {
	COUNTRIES_LIST,
	FILE_TYPES,
	FORM_TYPES,
	FORM_VALIDATE_RULES,
	INPUTS_ERRORS,
	MODAL_TYPES,
	PHONE_MASKS_LIST,
} from "../const";
import {initFileField} from "./inputs";
import { getSavedUtmParams } from "../preload";
// import { getCaptchaToken, resetCaptchaToken } from "./captcha";

const MAX_FILE_SIZE = 25;
let initModals = [];

/** Инициализировать форму.
 * @param {HTMLFormElement|HTMLButtonElement} nodeForm Либо сама форма, либо кнопка, которая открывает модалку с формой.
 * @param {string} defaultSuccessType Тип модалки при успешной отправке по умолчанию.
 * @param {boolean} isModal Это модалка?
 */
const makeForm = (nodeForm, defaultSuccessType, isModal = false) => {
	let formState = {
		currentForm: nodeForm,
		title: null,
		formErrors: {},
		metrikKey: "",
		gtmKey: "",
		modalKey: "",
		phoneMask: null,
		requiredValues: {},
		requiredInputsLength: 0,
		submitButton: null,
		successType: defaultSuccessType,
	};

	const setInputError = ({ dataName }) => {
		const errorNode = formState.currentForm.querySelector(`[data-input="${dataName}"]`);
		const errorText = formState.formErrors[dataName] ?? "";

		if (errorNode) {
			errorNode.innerText = errorText;
		} else {
			setAdditionalError(dataName, errorText);
		}
	}

	const resetInputError = ({ dataName }) => {
		const errorNode = formState.currentForm.querySelector(`[data-input="${dataName}"]`);
		if (errorNode) {
			errorNode.innerText = "";
		}
	}

	const setAdditionalError = (dataName, errorText) => {
		delete formState.formErrors[dataName];

		const additionalErrors = formState.currentForm.querySelector(`[data-input="additional"]`);
		if (additionalErrors) {
			if (additionalErrors.innerHTML !== '') {
				additionalErrors.innerHTML += '<br>';
			}
			additionalErrors.innerHTML += errorText;
		}
	};

	const resetAdditionalErrors = () => {
		const additionalErrors = formState.currentForm.querySelector(`[data-input="additional"]`);
		if (additionalErrors) {
			additionalErrors.innerHTML = "";
		}
	}

	const setDisabledBtn = () => {
		const errorsLength = Object.keys(formState.formErrors).length;
		const valuesLength = Object.keys(formState.requiredValues).length;
		const isValidInputs = errorsLength === 0 && valuesLength === formState.requiredInputsLength;

		if (isValidInputs) {
			formState.submitButton.removeAttribute('disabled');
		} else {
			formState.submitButton.setAttribute('disabled', true);
		}
	}

	const showLoader = () => {
		formState.submitButton.setAttribute('disabled', true);
		formState.submitButton.classList.add('form--loader');
	}

	const hideLoader = () => {
		formState.submitButton.removeAttribute('disabled');
		formState.submitButton.classList.remove('form--loader');
	}

	const validateInput = (elem) => {
		const {
			value = "",
			dataset = {},
			attributes = {}
		} = elem;

		const required = attributes?.required ?? false;
		const dataName = dataset?.name ?? "";
		const regex = new RegExp(FORM_VALIDATE_RULES[dataName]);

		let isValid = regex.test(value);
		let errors = { ...formState.formErrors };

		if (value.length === 0 && required) {
			errors[dataName] = INPUTS_ERRORS.required;
		} else if (value.length > 0 && !isValid) {
			errors[dataName] = INPUTS_ERRORS[dataName];
		} else {
			delete errors[dataName];
		}

		formState.formErrors = errors;
		setDisabledBtn();
	}

	const updatePhoneMask = (value) => {
		const country = checkPhoneCountry(value);
		const update = mask => formState.phoneMask.updateOptions(mask);

		switch(country) {
			case COUNTRIES_LIST.by:
				update({ mask: PHONE_MASKS_LIST.by });
				break;
			default:
				update({ mask: PHONE_MASKS_LIST.ru });
		}
	}

	const handleInputs = (e) => {
		const input = e.target;
		const { name: inputName, value, required = false } = input;

		if (inputName === "phone") {
			updatePhoneMask(value);
		}

		if (required) {
			formState.requiredValues[inputName] = value;
		}

		validateInput(input);
		resetInputError({ dataName: input?.dataset?.name })
	}

	const handleInputsBlur = (e) => {
		const input = e.target;
		validateInput(input);
		setInputError({ dataName: input?.dataset?.name });
	}

	const addUtmParamsToRequest = (formData) => {
		const utmParams = getSavedUtmParams();
		if (utmParams.length != 0) {
			formData.append('utm_params', JSON.stringify(utmParams));
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		resetAdditionalErrors();
		showLoader();

		const formData = new FormData(formState.currentForm);
		if (formState.phoneMask) {
			formData.delete("phone");
			formData.append("phone", "+" + formState.phoneMask.unmaskedValue);
		}

		addUtmParamsToRequest(formData);

		Fetch.post('/feedback/', formData)
			.then(res => {
				if (res.ok) {
					sendMetrika(formData);

					if (formState.modalKey) {
						closeModal(MODAL_TYPES[formState.modalKey]);
					}

					formState.currentForm.reset();
					formState.requiredValues = {};
					formState.currentForm.querySelector(".jsClearFileBtn")?.dispatchEvent(new CustomEvent('click'));
					initSuccessModal();
					formState.successType !== "jsSuccessBlock" ? openModal(formState.successType) : openBlock(formState.successType);
					setDisabledBtn();

				} else {
					res.text().then(text => {
						const errors = JSON.parse(text);
						if (typeof errors === 'object') {
							Object.keys(errors).forEach(key => {
								formState.formErrors[key] = Array.isArray(errors[key]) ? errors[key][0] : errors[key];
								setInputError({ dataName: key });
							});
						}
					});
				}
			})
			.catch((err) => {
				console.log(err);
				if (formState.modalKey) {
					closeModal(MODAL_TYPES[formState.modalKey]);
				}
				openModal(MODAL_TYPES.fail_feedback);
			})
			.finally(() => {
				hideLoader();
				formState.submitButton.setAttribute('disabled', true);
			})
	}

	const sendMetrika = (formData) => {
		// Yandex metrika.
		if (formState.metrikKey && typeof ym !== 'undefined') {
			ym(ym.a[0][0], 'reachGoal', formState.metrikKey);
		}

		// Calltouch
		const ctModId = document.querySelector('meta[name="calltouch-mod-id"]')?.getAttribute('content');
		const ctSiteId = document.querySelector('meta[name="calltouch-site-id"]')?.getAttribute('content');
		if (ctModId && ctSiteId && typeof ct !== 'undefined') {
			const ctData = {
				fio: formData.get('name'),
				phoneNumber: formData.get('phone'),
				email: formData.get('email'),
				comment: formData.get('text'),
				subject: formState.title ?? 'Заявка с сайта',
				requestUrl: location.href,
			};
			const sessionId = window.ct('calltracking_params', ctModId)?.sessionId;
			if (sessionId) {
				ctData['sessionId'] = sessionId;
			}

			fetch(`https://api.calltouch.ru/calls-service/RestAPI/requests/${ctSiteId}/register/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: new URLSearchParams(ctData),
			});
		}

		// GTM
		if (formState.gtmKey) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ 'event': formState.gtmKey });
		}
	}

	const validateInputFile = ({ type, size }) => {
		const inputFileNode = formState.currentForm.querySelector(".jsFileInput");
		const inputDataName = inputFileNode.dataset.name;
		const sizeInMB = Number(size / (1024 * 1024));
		const isTypeValid = FILE_TYPES.includes(type);
		const isSizeValid = sizeInMB <= MAX_FILE_SIZE;

		if (isTypeValid === false || isSizeValid === false) {
			let error = "";
			if (isTypeValid === false && isSizeValid === false) {
				error = INPUTS_ERRORS.file_type;
			} else if (isTypeValid && isSizeValid === false) {
				error = INPUTS_ERRORS.file_size;
			} else if (isTypeValid === false && isSizeValid) {
				error = INPUTS_ERRORS.file_type;
			}
			formState.formErrors[inputDataName] = error;
			setDisabledBtn();
			setInputError({ dataName: inputDataName });
		}
	}

	const handleFileUpload = (input, file) => {
		const fileType = file.type ?? "";
		const fileSize = file.size ?? "";

		resetInputError({ dataName: input.dataset.name });
		validateInputFile({ type: fileType, size: fileSize });
	}

	const handleFileClear = (input) => {
		delete formState.formErrors[input.dataset.name];
		setDisabledBtn();
		resetInputError({ dataName: input.dataset.name });
	}

	const initMetrika = () => {
		const metrikKey = nodeForm.dataset.metrikKey;
		const gtmKey = nodeForm.dataset.gtmKey;
		formState.metrikKey = metrikKey;
		formState.gtmKey = gtmKey;
	}


	const setModalTitle = (modalTitle, title) => {
		if (modalTitle && title) {
			modalTitle.innerText = title;
			formState.title = title;
		}
	}

	const setModalBgColor = (modal, bgColor) => {
		if (!modal) {
			return;
		}

		modal.style.backgroundColor = bgColor ? bgColor : '';
	}

	const initSuccessModal = () => {
		// Если у самой формы задан тип успешной модалки, мы переопределяем дефолтный.
		const formSuccessType = formState.currentForm.dataset.successType
		if (formSuccessType && MODAL_TYPES[formSuccessType] !== undefined) {
			formState.successType = MODAL_TYPES[formSuccessType];
		}

		// Обновление заголовка и текста успешной модалки, если они указаны.
		const successModal = document.querySelector(`.${formState.successType}`);
		if (successModal) {
			const successTitle = nodeForm.dataset.successTitle;
			const successText = nodeForm.dataset.successText;
			const successTitleEl = successModal.querySelector('.jsSuccessTitle');
			const successTextEl = successModal.querySelector('.jsSuccessText');
			if (successTitle && successTitleEl) {
				successTitleEl.innerText = successTitle;
			}
			if (successText && successTextEl) {
				successTextEl.innerText = successText;
			}
		}
	}

	const initForm = () => {
		const inputsRequiredNodes = formState.currentForm.querySelectorAll("[required]");
		const inputPhoneNode = formState.currentForm.querySelector(".jsInputPhone");
		const btnSubmit = formState.currentForm.querySelector('.jsSubmitButton');
		const formTitle = formState.currentForm.querySelector('.jsFormTitle')
			?? formState.currentForm.previousSibling;
		if (formTitle && formTitle.innerText && formTitle.classList.contains('jsFormTitle')) {
			formState.title = formTitle.innerText;
		}

		formState.requiredInputsLength = inputsRequiredNodes.length ?? 0;

		if (inputPhoneNode) {
			formState.phoneMask = IMask(inputPhoneNode, { mask: PHONE_MASKS_LIST.default });
		}

		formState.submitButton = btnSubmit;
		formState.currentForm.addEventListener("submit", handleSubmit);
	}

	const initModalForm = (formKey) => {
		const form = document.querySelector(`.${FORM_TYPES[formKey]}`);

		const inputOrderTypeNode = form.querySelector(".jsOrderType");
		inputOrderTypeNode.value = formKey;

		formState.currentForm = form;
		formState.modalKey = formKey;

		initSuccessModal();
		initForm();
		addInputsListeners();
	}

	const addInputsListeners = () => {
		const inputFileNode = formState.currentForm.querySelector(".jsFileInput");
		if (inputFileNode) {
			const fileLabelNode = formState.currentForm.querySelector(".jsFileLabel");
			const btnClearFileNode = formState.currentForm.querySelector(".jsClearFileBtn");

			initFileField(inputFileNode, fileLabelNode, btnClearFileNode, handleFileUpload, handleFileClear);
		}

		const inputsNodes = formState.currentForm.querySelectorAll(".jsInputValidate");
		inputsNodes.forEach(input => {
			input.addEventListener("input", handleInputs);
			input.addEventListener("blur", handleInputsBlur);
			input.dispatchEvent(new CustomEvent('input'));
		});
	}

	initMetrika();

	if (isModal) {
		const btnType = nodeForm.dataset.type;
		const modalType = MODAL_TYPES[btnType];
		const modalTitle = nodeForm.dataset.modalTitle;
		const modal = document.querySelector(`.${MODAL_TYPES[btnType]}`);
		const title = modal.querySelector(".jsModalTitle");
		const formPk = modal.querySelector(".jsFormPk");

		if (!initModals.includes(btnType)) {
			initModals.push(btnType);

			initModalForm(btnType);
		}

		nodeForm.addEventListener("click", (e) => {
			e.preventDefault();

			if (nodeForm.dataset.formPk !== undefined) {
				formPk.value = nodeForm.dataset.formPk;
			}
			setModalBgColor(modal, nodeForm.dataset.bgColor)
			setModalTitle(title, modalTitle);
			openModal(modalType);
		})
	} else {
		initSuccessModal();
		initForm();
		addInputsListeners();
	}
}

export const modalFeedbackForm = (container = document) => {
	const btnsModalList = Array.from(container.querySelectorAll(".jsOpenModalBtn"));
	btnsModalList.forEach(btn => {
		makeForm(btn, MODAL_TYPES.success_feedback, true);
	})
}

export const careerForm = () => {
	const careerForms = Array.from(document.querySelectorAll(".jsCareerForm"));
	careerForms.forEach(careerForm => makeForm(careerForm, MODAL_TYPES.success_resume))
}

export const projectStartForm = () => {
	const projectForms = Array.from(document.querySelectorAll(".jsStartProjectForm"));
	projectForms.forEach(projectForm => makeForm(projectForm, MODAL_TYPES.success_feedback))
}

export const subscribeForm = () => {
	const subscribeForms = Array.from(document.querySelectorAll(".jsSubscribeForm"));
	subscribeForms.forEach(subscribeForm => makeForm(subscribeForm, MODAL_TYPES.success_feedback))
}

export const academyForm = () => {
	const academyForms = Array.from(document.querySelectorAll(".jsAcademyForm"));
	academyForms.forEach(form => makeForm(form, MODAL_TYPES.success_feedback))
}

export const rndForm = () => {
	const rndForms = Array.from(document.querySelectorAll(".jsRndForm"));
	rndForms.forEach(form => makeForm(form, MODAL_TYPES.success_feedback))
}

export const informationSafetyForm = () => {
	const informationSafetyForm = Array.from(document.querySelectorAll(".jsInformationSafetyForm"));
	informationSafetyForm.forEach(form => makeForm(form, MODAL_TYPES.success_feedback))
}

export const informationSafetyCommentForm = () => {
	const informationSafetyCommentForm = Array.from(document.querySelectorAll(".jsInformationSafetyCommentForm"));
	informationSafetyCommentForm.forEach(form => makeForm(form, MODAL_TYPES.success_feedback))
}

export const oneCServicesForm = () => {
	const oneCServicesForm = Array.from(document.querySelectorAll(".jsOneCServicesForm"));
	oneCServicesForm.forEach(form => makeForm(form, MODAL_TYPES.success_feedback))
}

export const eventRegisterForm = () => {
	const forms = Array.from(document.querySelectorAll(".jsFormEventRegister2"));
	forms.forEach(form => makeForm(form, MODAL_TYPES.success_feedback))
}

export const onecCoursePlanForm = () => {
	const coursePlanForms = Array.from(document.querySelectorAll(".jsFormOneCCoursePlan"));
	coursePlanForms.forEach(form => makeForm(form, MODAL_TYPES.success_block))
}
