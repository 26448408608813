import { KEY } from "../const";

const html = document.querySelector('html');
const body = document.querySelector('body');
let escKeydownFn = null;
let modalOverlayClickFn = null;
let closeBtnFns = [];

function querySelector(selector) {
	if (selector instanceof HTMLElement) {
		return selector;
	}
	return document.querySelector(`.${selector}`);
}

const showContent = (modalType) => {
	const content = querySelector(modalType);
	content?.classList?.add("show");
	triggerCloseModal(modalType);
	// add animation show
}

const hiddenContent = (modalType) => {
	const content = querySelector(modalType);
	content?.classList?.remove("show");
	removeEventListeners(content);
	// add animation hidden
}

const openModal = (modalType) => {
	const modal = querySelector("jsModal");
	modal?.classList?.add("modal--open");
	html?.classList.add("locked");
	body?.classList.add("locked");
	showContent(modalType);
}

const closeModal = (modalType) => {
	const modal = querySelector("jsModal");
	modal?.classList?.remove("modal--open");
	html?.classList.remove("locked");
	body?.classList.remove("locked");
	hiddenContent(modalType);
}

const openBlock = (modalType) => {
	const block = querySelector(modalType);	
	block?.classList?.remove("hide-block");
	showContent(modalType);
}

const closeBlock = (modalType) => {
	const block = querySelector(modalType);
	block?.classList?.remove("show-block");
	block?.classList?.add("hide-block");
}

const triggerCloseModal = (modalType) => {
	const modal = querySelector(modalType);
	const modalOverlayNode = querySelector("jsModal");

	if(modal) {
		const closeBtnsList = Array.from(modal.querySelectorAll(".jsCloseModalBtn"));

		closeBtnsList.forEach(btn => {
			const clickFn = (e) => {
				e.preventDefault();
				modalType !== 'jsSuccessBlock' ? closeModal(modalType) : closeBlock(modalType);
			}
			closeBtnFns.push(clickFn);
			btn.addEventListener('click', clickFn);
		});

		modalOverlayClickFn = function(e) {
			if (e.target.classList.contains("jsModal")) {
				closeModal(modalType);
			}
		}
		modalOverlayNode.addEventListener("click", modalOverlayClickFn)

		escKeydownFn = function (e) {
			if (e.which == KEY.escape) {
				closeModal(modalType);
			}
		};
		document.addEventListener('keydown', escKeydownFn);
	}
}

const removeEventListeners = (modal) => {
	const modalOverlayNode = querySelector("jsModal");
	const closeBtnsList = Array.from(modal.querySelectorAll(".jsCloseModalBtn"));

	closeBtnsList.forEach((btn, index) => {
		btn.removeEventListener('click', closeBtnFns[index]);
	});
	closeBtnFns = [];

	modalOverlayNode.removeEventListener("click", modalOverlayClickFn);
	modalOverlayClickFn = null;

	document.removeEventListener('keydown', escKeydownFn);
	escKeydownFn = null;
}

const initModalButtons = () => {
	const btns = Array.from(document.getElementsByClassName('jsOpenModal'));
	btns.forEach(btn => {
		const modalClass = btn.dataset.modal;
		if (!modalClass) {
			return;
		}

		const modal = document.getElementsByClassName(modalClass)[0];
		if (!modal) {
			return;
		}

		btn.addEventListener('click', () => {
			openModal(modal);
		})
	})
}

export {
	openModal,
	closeModal,
	hiddenContent,
	showContent,
	initModalButtons,
	openBlock
}
