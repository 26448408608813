import { getCookie, setCookie } from "./cookie";

const COOKIE_NAME = 'cookie_notif';
const COOKIE_DISABLED = '0';

export default function initCookieNotification() {
    const showNotif = getCookie(COOKIE_NAME);
    if (showNotif === COOKIE_DISABLED) {
        return;
    }

    const notif = document.getElementsByClassName('js-cookie-notification')[0];
    if (!notif) {
        return;
    }

    notif.classList.add('cookie-notif--visible');
    document.getElementsByClassName('js-cookie-notification-btn')[0].addEventListener('click', () => {
        notif.remove();
        setCookie(COOKIE_NAME, COOKIE_DISABLED, 365);
    })
}
