export const showMore = () => {
  const block = document.querySelector(".jsShowMoreBlock");
  const button = document.querySelector(".jsShowMoreBtn");
  const buttonText = document.querySelector(".jsShowMoreBtnText");

  if (block !== null) {
    let isOpened = block.classList.contains("opened");

    const showOrHide = () => {
      if (isOpened) {
        block.classList.add("opened");
        button.classList.add("opened");
        buttonText.innerText = "Скрыть";
      } else {
        block.classList.remove("opened");
        button.classList.remove("opened");
        buttonText.innerText = "Показать все";
      }
    };

    const toggleShow = () => {
      isOpened = !isOpened;
      showOrHide();
    };

    showOrHide();
    button?.addEventListener("click", toggleShow);
  }
};
