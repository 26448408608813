import { careerForm } from "./form";

/** На деталке вакансии превращает информацию в аккордеон
 *  и показывает форму отправки резюме */
export default () => {
  const btnOpenVacancyForm = document.querySelector(".js-vacancy-open-form");
  const titleVacancyForm = document.querySelector(".js-vacancy-info-title");
  const infoBoxVacancyForm = document.querySelector(".js-vacancy-info-box");
  const formBoxVacancyForm = document.querySelector(".js-vacancy-form-box");

  function changeInfoToForm() {
    infoToAccordion();
    showForm();
  }

  function infoToAccordion() {
    titleVacancyForm.classList.add("vacancy-detail__accordion");
    btnOpenVacancyForm.classList.add("visually-hidden");
    titleVacancyForm.addEventListener("click", toggleShowAccordion);
  }

  function toggleShowAccordion() {
    titleVacancyForm.classList.toggle("vacancy-detail__accordion--opened");
    infoBoxVacancyForm.classList.toggle("vacancy-detail__info-box--hidden");
  }

  function showForm() {
    formBoxVacancyForm.classList.add("vacancy-detail__form-box--visible");
    infoBoxVacancyForm.classList.add("vacancy-detail__info-box--hidden");
  }

  if (btnOpenVacancyForm) {
    btnOpenVacancyForm.addEventListener("click", changeInfoToForm);
  }
};
