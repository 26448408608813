export const initFileField = (input, label, clearBtn, changeFileFn = null, clearFileFn = null) => {
    const labelDefaultText = label.innerText;

    input.addEventListener("input", (e) => {
        const file = e.target.files[0];
        const fileName = file.name;

        label.innerText = fileName;
        clearBtn.classList.add("show");

        changeFileFn?.call(null, input, file);
    });
    clearBtn.addEventListener('click', (e) => {
        e.preventDefault();

        label.innerText = labelDefaultText;
        input.value = "";
        clearBtn.classList.remove("show");

        clearFileFn?.call(null, input, e)
    });
};
