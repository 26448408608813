import Fetch from "./fetch";
import NiceSelect from '../library/nice-select2/src/js/nice-select2'
class AsyncSelect {
  constructor(elem, url, config) {
    this.config = config;
    this.elem = elem;
    this.url = url;
    this.page = 1;
    this.limit = 20;
    this.disabledSearch = false;
    this.valueInput = "";

    this.init();
  }

  async getData(value) {
    const params = new URLSearchParams({
      search: value,
      page: this.page,
    });
    const res = await Fetch.get(`${this.url}/?${params}`);
    return await res.json();
  }

  async onSearch(value) {
    this.disabledSearch = false;
    this.page = 1;

    const res = await this.getData(value);

    this.valueInput = value;

    return res.map((elem) => ({ text: elem, value: elem })) || [];
  }

  async asyncGetData() {
    if (!this.disabledSearch) {
      this.page += 1;

      const res = await this.getData(this.valueInput);

      if (res.length < this.limit) {
        this.disabledSearch = true;
      }

      return res.map((elem) => ({ text: elem, value: elem })) || [];
    }
    return [];
  }

  init() {
    this.onSearch(this.valueInput).then((res) => {
      const select = NiceSelect.bind(this.elem, {
        searchable: true,
        data: res,
        selectedOptions: this.config.firstSelected ? {data:res[0]} : null,
        labelSearch: "Поиск...",
        onSearch: this.onSearch.bind(this),
        asyncGetData: this.asyncGetData.bind(this),
        placeholder: "Название",
        classContainer: "gitlab_projects--create_report-select",
        ...this.config,
      });

      if (this.config.firstSelected) {
        select.updateSelectValue();
      }
    });
  }
}

export default AsyncSelect;
