export const tableMnemonic =()=>{
  const tdElements = document.querySelectorAll('td');

  // Регулярное выражение для поиска !v или !x (независимо от регистра)
  const regex = /(![vx])(.*)/i;

  // Проходимся по каждому <td> элементу
  tdElements.forEach(td => {
    // Получаем текст из текущего <td>
    const text = td.textContent;

    // Проверяем, соответствует ли текст регулярному выражению
    const match = text.match(regex);

    if (match) {
      // Создаем новый элемент <span> для задней картинки
      const spanElement = document.createElement('span');

      // Добавляем класс в зависимости от !v или !x (независимо от регистра)
      if (match[1].toLowerCase() === '!v') {
        spanElement.className = 'icon-v';
      } else if (match[1].toLowerCase() === '!x') {
        spanElement.className = 'icon-x';
      }

      // Создаем новый текстовый узел с текстом после !v или !x
      const textNode = document.createTextNode(match[2]);

      // Очищаем содержимое <td> и добавляем span и текст после !v или !x
      td.innerHTML = '';
      td.appendChild(spanElement);
      td.appendChild(textNode);
    }
  });

}