export default function initFullscreenImage(buttons) {
    buttons.forEach(btn => {
        const image = btn.dataset.imgSrc
            ? btn.dataset.imgSrc
            : btn.parentNode.querySelector('.js-image').src;

        btn.addEventListener('click', () => {
            document.body.classList.add('scroll-lock');
            document.body.classList.add('padding-scroll');

            const fullscreenImage = document.createElement('div');
            fullscreenImage.classList.add('fullscreen-image');
            document.body.append(fullscreenImage);

            fullscreenImage.innerHTML = '<img src=' + image + '>';

            fullscreenImage.addEventListener('click', () => {
                document.body.classList.remove('scroll-lock');
                document.body.classList.remove('padding-scroll');
                fullscreenImage.remove();
            });
        });
    });
}
