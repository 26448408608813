import { KEY } from "../const";

const toggleMenuScrollState = (headerSelector) => {
  const header = document.querySelector(headerSelector);
  document.addEventListener("scroll", () => {
    if (window.scrollY != 0) {
      header.classList.add("header--shadow");
    } else {
      header.classList.remove("header--shadow");
    }
  });
  document.dispatchEvent(new CustomEvent("scroll"));
};

export { toggleMenuScrollState };

export default () => {
  let isMenuOpen = false;
  const menu = document.querySelector(".js-menu");
  const menuBtn = document.querySelector(".js-menu-button");
  const gamburger = document.querySelector(".js-gamburger");

  const escKeydownFn = (e) => {
    if (e.which == KEY.escape) {
      menuBtn.dispatchEvent(new CustomEvent('click'));
    }
  }

  const toggleOpenMenu = () => {
    if (isMenuOpen) {
      menu?.classList.remove("active");
      gamburger?.classList.remove("active");
      document.body.classList.remove("scroll-lock");
      document.removeEventListener('keydown', escKeydownFn);
    } else {
      menu?.classList.add("active");
      gamburger?.classList.add("active");
      document.body.classList.add("scroll-lock");
      document.addEventListener('keydown', escKeydownFn);
    }
    isMenuOpen = !isMenuOpen;
  };

  menuBtn?.addEventListener("click", toggleOpenMenu);
};
