/** На деталке кейса в разделе инфографика */
export default () => {
  const itemsList = document.querySelectorAll(".js-infographics-item");
  const unitsList = document.querySelectorAll(".js-infographics-unit");
  const diagramsList = document.querySelectorAll(".js-infographics-diag");

  // Анимация при появлении диаграммы на экране
  let observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        // Если браузер не поддерживает IntersectionObserver V2, тогда не используем
        // св-во isVisible, иначе анимация не будет проигрываться.
        if (entry.isIntersecting && (!('isVisible' in entry) || entry.isVisible)) {
          const units = entry.target.querySelectorAll(".js-infographics-unit");
          units.forEach(function (item) {
            item.classList.add("unit--animated");
          });
          observer.unobserve(entry.target);
        }
      });
    },
    {
      delay: 100,
      threshold: 1,
      trackVisibility: true,
    }
  );

  diagramsList.forEach((el, i) => {
    observer.observe(el);
  });

  // Ховер-эффекты для частей диаграммы и легенды
  function addItemsOpacity() {
    itemsList.forEach(function (item) {
      item.classList.add("opacity");
    });
  }

  function removeItemsOpacity() {
    itemsList.forEach(function (item) {
      item.classList.remove("opacity");
    });
  }

  function addUnitsOpacity() {
    unitsList.forEach(function (item) {
      item.classList.add("opacity");
    });
  }

  function removeUnitsOpacity() {
    unitsList.forEach(function (item) {
      item.classList.remove("opacity");
    });
  }

  itemsList.forEach(function (item, index) {
    item.addEventListener("mouseover", function () {
      addItemsOpacity();
      addUnitsOpacity();
      unitsList[index].classList.add("hovered");
      itemsList[index].classList.add("hovered");
    });

    item.addEventListener("mouseout", function () {
      removeItemsOpacity();
      removeUnitsOpacity();
      unitsList[index].classList.remove("hovered");
      itemsList[index].classList.remove("hovered");
    });
  });

  unitsList.forEach(function (unit, index) {
    unit.addEventListener("mouseover", function () {
      addItemsOpacity();
      addUnitsOpacity();
      itemsList[index].classList.add("hovered");
    });

    unit.addEventListener("mouseout", function () {
      removeUnitsOpacity();
      removeItemsOpacity();
      itemsList[index].classList.remove("hovered");
    });
  });
};
