import { COUNTRIES_LIST, COUNTRIES_CHAR } from "../const";

export function checkPhoneCountry(value) {
  const firstChar = value[0];
  const secondChar = value[1];
  const countryChar = value[3];

  for (let key in COUNTRIES_CHAR) {
    if(countryChar === COUNTRIES_CHAR[key] || (firstChar === "+" && secondChar === COUNTRIES_CHAR[key])) {
      return COUNTRIES_LIST[key];
    }  
  }
}