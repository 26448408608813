const getParent = (elem) => {
  return elem?.parentElement;
}

const getVideo = (currentTarget) => {
  const parent = getParent(currentTarget);
  
  if(parent.classList.contains("jsVideoWrapper")) {
    return parent.querySelector(".jsVideo");
  } 

  if(parent.parentElement.classList.contains("jsVideoWrapper")) {
    return parent.parentElement?.querySelector(".jsVideo")
  }
  
  return null;
}

const handleBtnPlayClick = (e, btn) => {
  const video = getVideo(btn);

  video?.play();
  video?.addEventListener("play", handlePlayVideo);
  video?.addEventListener("pause", handlePauseVideo);
}

const removeListeners = (video) => {
  const parent = getParent(video);
  const btnPlay = parent.querySelector(".jsVideoBtnPlay");

  if(video.ended) {
    btnPlay?.removeEventListener('click', handleBtnPlayClick);
    video.removeEventListener("play", handlePlayVideo);
    video.removeEventListener("pause", handlePauseVideo);
  }
}

const handlePlayVideo = (e) => {
  const parent = getParent(e.target);
  parent?.classList.add("playing");
}

const handlePauseVideo = (e) => {
  const parent = getParent(e.target);
  parent?.classList.remove("playing");
  removeListeners(e.target);
}

export const eventsPlayVideos = () => {
  const btnsPlayNodes = document.querySelectorAll(".jsVideoBtnPlay");
  const btnsPlayArray = Array.from(btnsPlayNodes);

  btnsPlayArray?.forEach(btn => {
    btn.addEventListener('click', e => handleBtnPlayClick(e, btn));
  })
}



