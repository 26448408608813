export default () => {
  const copyTextBtns = Array.from(document.querySelectorAll('.js-copy-text'));

  if (!copyTextBtns.length) {
    return;
  }

  const selectAndToClipboardText = async (el) => {
    const { body } = document;
    const parent = el.closest('.js-report');
    
    const tempElement = document.createElement('div', {
      style: "position: absolute; left: 99999px"
    });
    tempElement.innerHTML = parent.innerHTML;

    const excludingElement = tempElement.querySelector('.js-report-date');
    excludingElement.style.display = "none";
    
    body.appendChild(tempElement)

    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(tempElement);
    
    selection.addRange(range);
  
    const selectedText = selection.toString();
    
    try {
      await navigator.clipboard.writeText(selectedText);
      el.classList.add('gitlab_projects--report_copy--done');
    } catch (err) {
      console.error('Unable to copy', err);
    } finally {
      body.removeChild(tempElement);
      selection.removeAllRanges();
      selection.empty();
    }
  }

  copyTextBtns.forEach(el => el.addEventListener('click', e => selectAndToClipboardText(el)))
}