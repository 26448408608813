const tabLinkActiveClass = "tabs--nav-link_active";
const tabPanActiveClass = "tabs--panel_active";

function clickOnTabLink(e, el) {
  e.preventDefault();
  const { target: targetLink } = e;
  const tabsContentArray = Array.from(
    document.querySelectorAll(".jsTabContent")
  );
  const activeLink = el?.querySelector(`.jsTabsLink.${tabLinkActiveClass}`);
  const activePanNode = el?.querySelector(`.jsTabContent.${tabPanActiveClass}`);
  const targetData = targetLink?.dataset.target?.replace("#", "");
  const targetPan = tabsContentArray?.find(el => el.id === targetData);

  if (targetLink.id !== activeLink.id) {
    activeLink?.classList.remove(tabLinkActiveClass);
    activePanNode?.classList.remove("show", tabPanActiveClass);

    targetLink?.classList.add(tabLinkActiveClass);
    targetPan?.classList.add(tabPanActiveClass, "show");
  }
}

export default () => {
  const tabsArray = Array.from(document.querySelectorAll(".jsTabs"));

  tabsArray?.forEach(el => {
    const linksArray = Array.from(el.querySelectorAll(".jsTabsLink"));

    linksArray?.forEach(link => {
      link?.addEventListener("click", e => clickOnTabLink(e, el));
    });
  });
};
