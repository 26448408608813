import Fetch from "./fetch";

export default () => {
    const addToFavoriteBtns = Array.from(document.getElementsByClassName('js-add-to-favorite'));
    if (!addToFavoriteBtns.length) {
        return;
    }

    addToFavoriteBtns.forEach(btn => {
        btn.addEventListener('click', (e) => {
            const target = e.currentTarget;
            const projectId = target.dataset.projectId;
            const isAddAction = target.dataset.isFavorite === 'false';
            const svg = target.querySelector('.js-add-to-favorite-svg');

            addProjectToFavorites(projectId, isAddAction).then(r => {
                target.dataset.isFavorite = isAddAction;

                if (isAddAction) {
                    svg.classList.add('project-favorite--active')
                } else {
                    svg.classList.remove('project-favorite--active')
                }
            });
        });
    });

    const addProjectToFavorites = (projectId, isAddAction) => {
        return Fetch.post(`/favorite_projects/${projectId}/${isAddAction ? 'add' : 'remove'}/`);
    }
}
