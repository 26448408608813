const svgGap = item => {
  const prevText = document.querySelectorAll(item);
  prevText.forEach((el, i) => {
    const svg = el?.querySelector('svg');
    if(!svg) {
      return;
    }
    svg?.removeAttribute('width');
    svg?.removeAttribute('height');
    if(i === 0) {
      const heightH = el.querySelector('h2')?.clientHeight;
      svg.style.top = `${heightH + 6}px`;
    } else {
      const h2 = el.querySelector('h2');
      if (!h2) {
        return;
      }

      const heightSvg = svg?.clientHeight
      h2.style.top = `${heightSvg - h2.clientHeight - 4}px`;
    }
  });
}
const b24Gap = () => {
  const btnWrapperB24 = document.querySelector(".js-custom-b24");
  if(innerWidth < 767) {
    btnWrapperB24?.classList.add("mob-btn-pos");
  }
  const companyBtn = document.querySelector(".cases-company__btn");
  const bottomFooter = document.querySelector(".footer--bottom");
  if(companyBtn) {
    bottomFooter?.classList.add("footer__p-bottom");
  } else {
    bottomFooter?.classList.remove("footer__p-bottom");
  }
}


export default () => {
  svgGap('.jsPrevText');
  b24Gap();

  window.addEventListener('resize', () => {
    svgGap('.jsPrevText')
    b24Gap();
  })

  
}

