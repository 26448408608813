/** Анимация счетчика цифр. Срабатывает на наличие класса js-animation-counter. Берет данные из дата-атрибутов.
 * @params [data-counter-num] - конечное число
 * @params [data-counter-duration] - время анимации в миллисекундах, по умолчанию 500
 */
export default () => {
  const counters = [...document.querySelectorAll(".js-animation-counter")];

  const countUp = (value, target, duration) => {
    let valueNum = Number(value);
    if (isNaN(value)) {
      valueNum = Number(value.replace(/[^\d]/g, ''));

      if (isNaN(valueNum)) {
        return;
      }
    }
    if (valueNum <= 1) {
      return;
    }

    const stepDuration = Math.ceil(duration / valueNum);

    let count = 1;

    const interval = setInterval(() => {
      count = count + 1;
      target.innerHTML = count;

      if (count >= valueNum) {
        clearInterval(interval);
        target.innerHTML = value;
      }
    }, stepDuration);
  };

  if (counters.length > 0) {
    counters.forEach((el) => {
      el.innerHTML = 1;
    });

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          const { target, isIntersecting } = entry;

          const duration = Number(entry.target?.dataset?.counterDuration) || 500;
          const value = entry.target?.dataset?.counterNum || 0;

          if (isIntersecting) {
            countUp(value, target, duration);
            observer.unobserve(target);
          }
        });
      },
      {
        rootMargin: "-50px",
        threshold: 1,
      }
    );

    counters.forEach((el) => {
      observer.observe(el);
    });
  }
};
