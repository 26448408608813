export default async () => {
  const slidersNodes = document.querySelectorAll(".jsSwiper");
  const slidersArray = Array.from(slidersNodes);

  if (slidersArray.length > 0) {
    // import("swiper/css");
    const { Swiper, Mousewheel, Navigation, Autoplay, Pagination } = await import("swiper");

    const oneCSlides = document.querySelectorAll(".oneC-detail__slider-item");

    slidersArray.forEach((el) => {
      const elDataName = el.dataset.name;
      const elDataSpace = el.dataset.space;
      const elDataCentered = el.dataset?.centered;
      const elDataScrollSlides = el.dataset?.scrollSlides;
      const elDataLoopSlides = el.dataset?.loopSlides;
      const elDataAutoplay = el.dataset?.autoplay;
      const elDataAutoHeight = el.dataset?.autoHeight;
      const elDataCase = el.dataset?.case;
      const elDataOneCSlides = el.dataset?.onec;
      const elDataMouseWheel = el.dataset?.mouseWheel;
      const elDataSlideClick = el.dataset?.slideClick;
      const caseSlidesParams =
        elDataCase === "true"
          ? {
              loop: false,
              freeMode: true,
              autoplay: false,
              breakpoints: {
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                  centeredSlides: true,
                },
                768: {
                  slidesPerView: 1.5,
                },
                1024: {
                  slidesPerView: 1.75,
                  spaceBetween: 20,
                },
              },
            }
          : {};

      const scrollSlidesParams =
        elDataScrollSlides === "true"
          ? {
              loop: true,
              speed: 30000,
              autoplay: {
                delay: 0,
                disableOnInteraction: false,
              },
              centeredSlides: true,
              freeMode: true,
              preventInteractionOnTransition: true,
            }
          : {};

      const loopSlidesParams =
        elDataLoopSlides === "true"
          ? {
              loop: true,
              freeMode: true,
              autoplay: false,
            }
          : {};

      const oneCSlidesParams =
        elDataOneCSlides === "true"
          ? {
              loop: oneCSlides.length > 1,
              freeMode: true,
              autoplay: oneCSlides.length > 1,
              autoHeight: true,
              autoplay:
                oneCSlides.length > 1
                  ? {
                      delay: 10000,
                      disableOnInteraction: false,
                    }
                  : false,
              mousewheel: false,
              watchOverflow: oneCSlides.length === 1,
              navigation: {
                nextEl: ".oneC-next",
                prevEl: ".oneC-prev",
                disabledClass: "oneC-nav--disabled",
              },
              pagination: {
                el: ".oneC-pagination",
                bulletActiveClass: "oneC-pagination__item-active",
                bulletClass: "oneC-pagination__item",
                enabled: oneCSlides.length > 1,
              },
            }
          : {};

      new Swiper(`.${elDataName}`, {
        modules: [Navigation, Mousewheel, Autoplay, Pagination],
        speed: 500,
        slidesPerView: "auto",
        preventClicksPropagation: true,
        spaceBetween: elDataSpace ? Number(elDataSpace) : 0,
        resizeObserver: true,
        centeredSlides: elDataCentered === "true",
        slideActiveClass: "slide-active",
        rewind: true,
        autoplay:
          elDataAutoplay === "true"
            ? {
                delay: 5000,
                disableOnInteraction: true,
              }
            : {},
        autoHeight: elDataAutoHeight === "true",
        mousewheel:
          elDataAutoplay === "true" || elDataMouseWheel === "false"
            ? false
            : {
                releaseOnEdges: true,
              },
        navigation: {
          nextEl: `.swiper--next.${elDataName}-btn`,
          prevEl: `.swiper--prev.${elDataName}-btn`,
        },
        on: {
          init: function () {
            // Добавляет счетчик слайдов
            const current = document.querySelector(`.swiper--count.swiper--count_current_${elDataName}`);
            const all = document.querySelector(`.swiper--count.swiper--count_all_${elDataName}`);
            if (current) {
              current.innerHTML = this.realIndex + 1;
            }
            if (all) {
              all.innerHTML = this.slides.length;
            }
          },
          slideChange: function () {
            // Изменяет номер текущего слайда в счетчике
            const current = document.querySelector(`.swiper--count.swiper--count_current_${elDataName}`);
            if (current) {
              current.innerHTML = this.realIndex + 1;
            }
          },
          click: function () {
            if (!elDataSlideClick) {
              return;
            }
            // Переключает слайды при клике
            const isNext = this.clickedSlide.classList.contains("swiper-slide-next");
            const isActive = this.clickedSlide.classList.contains("slide-active");
            if (isActive) return;

            if (isNext) {
              this.slideNext();
            } else {
              this.slidePrev();
            }
          },
        },
        ...caseSlidesParams,
        ...scrollSlidesParams,
        ...loopSlidesParams,
        ...oneCSlidesParams,
      });
    });
  }
};
