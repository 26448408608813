export default () => {
  // В <body> если залогинился добавляется атрибут data-is-staff
  // При его наличии убираем сервис-воркер

  if ("serviceWorker" in navigator) {
    const urlsBlacklist = document.body.dataset.swBlacklist?.split(",") ?? [];
    document.body.removeAttribute('data-sw-blacklist')

    if ("isStaff" in document.body.dataset === true) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    } else {
      // Весь код регистрации у нас асинхронный.
      navigator.serviceWorker
        .register("/serviceWorker.js")
        .then((reg) => {
          const sendData = () => {
            navigator.serviceWorker.controller.postMessage({'blacklist': urlsBlacklist });
          }
          if (navigator.serviceWorker.controller) {
            sendData();
          } else {
            navigator.serviceWorker.addEventListener('controllerchange', sendData);
          }
        })
        .catch((err) => console.log(err));
    }
  }
};
