function openVideo(triggerVideoSelector, wrapperVideoSelector) {
    const video = document.querySelector(triggerVideoSelector);
    const videoWrapper = document.querySelector(wrapperVideoSelector)
    video.addEventListener('click', () => {
        videoWrapper.innerHTML = 
        `<video
            src=${videoWrapper.getAttribute('data-src-video')}
            autoplay
            controls
            controlsList="nodownload noremoteplayback"
            class="video__player"
        ></video>
        `
    })
}

export default openVideo