export function getCookie(name) {
    if (!document.cookie) {
        return null;
    }

    const cookie = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));

    if (cookie.length === 0) {
        return null;
    }

    return decodeURIComponent(cookie[0].split('=')[1]);
}

export function setCookie(name, value, days = null) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(value ?? '')  + expires + "; path=/";
}

