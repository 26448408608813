import AsyncSelect from "./asyncSelect";

export default () => {
    const intervalFormat = document.querySelector('#interval_format');
    const branch = document.getElementById('branch')
    const tag_from = document.querySelector(`#tag_from`);
    const tag_to = document.querySelector(`#tag_to`);
    const tag_new = document.querySelector(`#tag_new`);
    const form_create_gitlab_report = document.querySelector(`#form_create_gitlab_report`);
    const updated_after = document.querySelector(`#updated_after`);
    const updated_before = document.querySelector(`#updated_before`);

    const id = new URLSearchParams(window.location.search).get(
        "id"
    );

    intervalFormat?.addEventListener("change", function (e) {

        const tab_tag = document.querySelector(`.js--tag_type`);
        const tab_branch = document.querySelector(`.js--branch_type`);

        if (e.target.checked) {
            tab_tag?.classList.remove('active');
            tab_branch?.classList.add('active');
        } else {
            tab_tag?.classList.add('active');
            tab_branch?.classList.remove('active');
        }
    });
    form_create_gitlab_report?.addEventListener("submit", function (e) {
        if (intervalFormat.checked) {
            checkRequired(e, updated_after);
            checkRequired(e, updated_before);
        } else {
            checkRequired(e, tag_new);
        }
    });

    function checkRequired(e, elem) {
        if (!elem.value) {
            elem.parentNode.classList.add('required');
            e.preventDefault();
        } else {
            elem.parentNode.classList.remove('required');
        }
    }

    function onChangTagTo (tag_value) {
        if (tag_value) {
            tag_new.value = tag_value;
        } else {
            tag_new.value = '';
        }
        setValueSelect(tag_to, tag_value)
    }

    function setValueSelect (elem, value){
        elem.textContent = '';
        const el = document.createElement("option");
        el.innerHTML = value;
        el.setAttribute("value", value);
        el.setAttribute("selected", '');
        elem.appendChild(el)
    }

    new AsyncSelect(
        branch,
        `/projects/${id}/branches`,
        {placeholder: 'Ветка', onChange: (val) => setValueSelect(branch, val)})
    new AsyncSelect(
        tag_from,
        `/projects/${id}/tags`,
        {placeholder: 'Тег', firstSelected: true, onChange: (val) => setValueSelect(tag_from, val)})
    new AsyncSelect(
        tag_to,
        `/projects/${id}/tags`,
        {placeholder: 'Тег', onChange: onChangTagTo})
};
