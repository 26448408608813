const priceRange = () => {
	const rangeSliderNode = document.querySelector(".jsRange");
	const rangeInputNode = document.querySelector(".jsRangeInput");

	if (rangeSliderNode) {
		import('nouislider/dist/nouislider.css');
		import('nouislider').then(noUiSlider => {
			noUiSlider.create(rangeSliderNode, {
				start: [0],
				range: {
					min: [1000000],
					max: [10000000],
				},
				step: 100000,
			});

			const rangeSliderValueNode = document.querySelector(".jsRangeValue");

			rangeSliderNode.noUiSlider.on('update', function(values, handle) {
				const value = Number(values[handle]);
				rangeSliderValueNode.innerHTML = value.toLocaleString('ru-RU');
				rangeInputNode.value = value;
			})
		}).catch(err => console.log(err));
	}
}

export default priceRange;
