import Fetch from "./fetch";
import { modalFeedbackForm } from "./form";

export default () => {
  const button = document.querySelector(".js-more-button");
  const reloadButton = document.querySelector(".js-pagination-reload");
  const errorBlock = document.querySelector(".js-pagination-error");
  const linksBlock = document.querySelector(".js-pagination-links");

  button?.addEventListener("click", showMore);

  function showError() {
    button.remove();
    linksBlock?.remove();
    errorBlock?.classList?.remove("display-none");
    reloadButton?.addEventListener("click", () => {
      document.location.reload();
    });
  }

  function showMore() {
    const instanceId = button.attributes["data-instance_id"].value;
    const pageNum = button.attributes["data-page_num"].value;
    const modelName = button.attributes["data-model_name"].value;

    const activeTag = button.attributes?.["data-active_tag"]?.value;
    const search = button.attributes?.["data-search"]?.value;
    const category = button.attributes?.["data-category"]?.value;
    const status = button.attributes?.["data-status"]?.value;
    const eventType = button.attributes?.["data-event-type"]?.value;

    const listBlock = document.getElementById(
      `paginated__list-${instanceId}`
    );
    const currentUrl = new URL(window.location);

    currentUrl.searchParams.set('page', pageNum);
    history.pushState(null, null, currentUrl);

    const params = {
      component_model: modelName ?? "",
      instance_id: instanceId ?? "",
      page: pageNum ?? "",
      active_tag: activeTag ?? "",
      search: search ?? "",
      category: category ?? "",
      status: status ?? "",
      event_type: eventType ?? "",
    };

    let requestUrl = new URL(window.location.origin);
    for (let param in params) {
      if (params[param] !== "") {
        requestUrl.searchParams.set(param, params[param]);
      }
    }

    const initNewModals = (lastChild) => {
      let nextEl = lastChild.nextElementSibling;
      while(nextEl !== null) {
        modalFeedbackForm(nextEl);

        nextEl = nextEl.nextElementSibling;
      }
    }

    Fetch.get("/paginated_view_api/" + requestUrl.search)
      .then((response) => response.json())
      .then((parsedResponse) => {
        const lastChild = listBlock.children[listBlock.children.length - 1];
        listBlock.insertAdjacentHTML('beforeend', parsedResponse.html);

        initNewModals(lastChild);

        if (parsedResponse.has_next) {
          button.setAttribute("data-page_num", parsedResponse.page + 1);
        } else {
          button.remove();
        }
        linksBlock.innerHTML = parsedResponse.pagination_links_html;
      })
      .catch(() => {
        showError();
      });
  }
};
