import { API_URL } from "../const";
import { getCookie } from "./cookie";

class Fetch {
    /**
     * Send the request.
     * @param {string} url The URL to send the request to.
     * @param {object} options
     * @returns {Promise}
     */
    static async request(url, options) {
        let request = Object.assign({}, this.requestDefaults, options);

        try {
            return await fetch(API_URL + url, request);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /**
     * Send the GET request.
     * Internally it calls request.
     * @param {string} url The URL to send the request to.
     * @param {object} options The additional options, like headers, etc.
     * @returns {Promise}
     */
    static async get(url, options = {}) {
        return await this.request(url, {
            method: 'GET',
            ...options,
        });
    }

    /**
     * Send the POST request.
     * Internally it calls request.
     * @param {string} url The URL to send the request to.
     * @param {any} data
     * @param {object} options The additional options, like headers, etc.
     * @returns {Promise}
     */
    static async post(url, data, options = {}) {
        return await this.request(url, {
            method: 'POST',
            body: data,
            ...options,
        });
    }

    /**
     * The default request options.
     */
    static get requestDefaults() {
        return {
            headers: {
                'Accept': 'application/json, text/plain, */*',
				'X-CSRFTOKEN': getCookie('csrftoken'),
            }
        };
    }
}

export default Fetch;
