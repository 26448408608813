import Swiper, {Pagination, Navigation, Autoplay} from "swiper";

export const initWebinarsSlider = () => {
    const webinarsSlider = document.querySelector(".webinars-swiper");

    if(!webinarsSlider) {
        return;
    }

    const webinarsSwiper = new Swiper(webinarsSlider, {
        modules: [Pagination, Navigation, Autoplay],
        slidesPerView: 1,
        spaceBetween: 20,
        slideActiveClass: 'webinars-item__active',
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".webinars-next",
            prevEl: ".webinars-prev",
          },
        pagination: {
            el: '.webinars-pagination',
            clickable: true,
            bulletActiveClass: 'webinars-pagination__bullet-active',
            bulletClass: 'webinars-pagination__bullet'
        }
    })
}