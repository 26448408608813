/** Добавляет css-переменную --vh */
export const customVh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

customVh();

window.addEventListener("resize", () => {
  customVh();
});

/** Добавляет css-переменную --start-count
 * для css-счетчиков в нумерованных списках */
export const customStartCount = () => {
  let lists = [...document.querySelectorAll("ol[start]")];

  if (lists.length > 0) {
    lists.forEach((el) => {
      const startCount = el.getAttribute("start");

      el.style.setProperty("--start-count", startCount - 1);
    });
  }
};
