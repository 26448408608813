export const initAccordion = (element, box) => {
    element.addEventListener('click', () => {
        box.classList.toggle('accordion--hidden');
        element.classList.toggle('accordion--showed');
    });
}

export default () => {
    const accordions = Array.from(document.getElementsByClassName('jsAccordion'));
    accordions.forEach(el => {
        if (el.nextElementSibling) {
            initAccordion(el, el.nextElementSibling);
        }
    });
}
