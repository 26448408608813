export default () => {
    const btn = document.getElementsByClassName('js-custom-b24')[0];
    if (!btn) {
        return;
    }
    const link = btn.dataset.link;
    if (!link) {
        return;
    }

    const clickFn = () => {
        btn.classList.add('custom-b24--loading');
        btn.removeEventListener('click', clickFn);

        const script = document.createElement('script');
        script.async = true;
        script.src = link + '?' + (Date.now() / 60000 | 0);
        script.addEventListener('load', () => {
            const originalButton = document.getElementsByClassName('b24-widget-button-inner-container')[0];
            originalButton?.dispatchEvent(new CustomEvent('click'));
            btn.remove();
        });
        const scripts = document.getElementsByTagName('script')[0];
        scripts.parentNode.insertBefore(script, scripts);
    }
    btn.addEventListener('click', clickFn);
}
