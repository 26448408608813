import { initFileField } from "./inputs";

export const employeePage = () => {
    if (!document.getElementsByClassName('jsEmployeeSection')[0]) {
        return;
    }

    const select = document.getElementsByClassName("jsThemeSelect")[0];
    if (select) {
        const themeBlocks = Array.from(document.querySelectorAll(".jsEmployeeSign"));
        let activeTheme = '';

        function hideBlocks() {
            themeBlocks.forEach(block => {
                block.classList.add('employee-hidden');
            });
        }

        select.addEventListener("change", () => {
            hideBlocks();

            activeTheme = select.value;
            const items = Array.from(document.querySelectorAll(`.jsEmployeeSign[data-theme="${activeTheme}"]`));

            items.forEach(item => {
                item.classList.remove('employee-hidden');
            });
        });
        select.dispatchEvent(new CustomEvent('change'));

        const copyBtn = document.getElementsByClassName('jsCopyHtml')[0];
        copyBtn.addEventListener('click', () => {
            const text = document.querySelector(`textarea.jsEmployeeSign[data-theme="${activeTheme}"]`);
            const result = document.getElementsByClassName('jsCopyResult')[0];
            const error = document.getElementsByClassName('jsCopyError')[0];

            if (navigator?.clipboard?.write) {
                const type = "text/html";
                const blob = new Blob([text.value], { type });
                const data = [new ClipboardItem({ [type]: blob })];

                navigator.clipboard.write(data).then((() => {
                    result.innerText = 'Скопировано.';
                })).catch(() => {
                    error.innerText = 'Ошибка копирования.';
                })

                setTimeout(() => {
                    result.innerText = '';
                    error.innerText = '';
                }, 2000);
            } else {
                error.innerHTML = 'Не поддерживается вашим браузером.<br>Выделите подпись из раздела "Превью" и скопируйте его(ctrl + c).';
            }
        });
    }

    const fileInput = document.getElementsByClassName('jsFileInput')[0];
    if (fileInput) {
        const fileLabel = document.getElementsByClassName('jsFileLabel')[0];
        const clearBtn = document.getElementsByClassName('jsClearFileBtn')[0];
        initFileField(fileInput, fileLabel, clearBtn);
    }
};
